export function isDevelopment(): boolean {
  const val = process.env.NODE_ENV === "development" || window.location.hostname === "localhost"
  if (val) {
    console.log("*** running in development mode ***");
  }
  return val;
}

export function isStaging(): boolean {
  const val = process.env.REACT_APP_ENV === "staging";
  if (val) {
    console.log("*** running in staging mode ***");
  }
  return val;
}

export function isProduction(): boolean {
  if (window.location.hostname === "localhost") {
    console.log("*** running production build on localhost ***");
    return false;
  }
  const val = process.env.NODE_ENV === "production";
  if (val) {
    console.log("*** running in production mode ***");
  }
  return val;
}