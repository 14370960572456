import { useEffect, useState } from "react";
import Box from "@awsui/components-react/box";
import Button from "@awsui/components-react/button";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import Table from "@awsui/components-react/table";

import moment from "moment";
import { listTemplates, postUserAction } from "../resources/api";

// {
//   "Content": {
//       "Value": "  \u003cp\u003e\n  \u003cb\u003eSubject: \u003c/b\u003eDedicated Solutions Architect at AWS\n  \u003c/p\u003e\n  \u003cp\u003eHi \u0026lt;INSERT CUSTOMER NAME\u0026gt;,\u003c/p\u003e\n  \u003cp\u003e\n    I am emailing you today to introduce myself as your dedicated AWS Senior Solutions Architect (SA). I am part of your AWS #OneTeam along with your Account Manager Michael Pinkstaff and the team of AWS specialists available to the {{ account }} team.\n  \u003c/p\u003e\n  \u003cp\u003e\n    I see your workloads primarily use {{ services }}.\n  \u003c/p\u003e\n  \u003cp\u003e\n    Based on this usage, I'm sharing some recent AWS updates that may directly benefit you.\n  \u003c/p\u003e\n  \u003cp\u003e\n      I am available to meet with your team to review your existing AWS workloads and help with:\n      \u003cul\u003e\n\u003cli\u003eOptimizing costs\u003c/li\u003e\n\u003cli\u003eReviewing/Designing your system and application architectures\u003c/li\u003e\n\u003cli\u003eIntroducing AWS services such as \u003ca href=\"https://aws.amazon.com/guardduty/\"\u003eAmazon GuardDuty\u003c/a\u003e for continuous account monitoring and threat detection\u003c/li\u003e\n\u003cli\u003eTraining and Technical Resource suggestions\u003c/li\u003e\n\u003cli\u003eFacilitating introductions to service specialists and AWS technology and consulting partners\u003c/li\u003e\n\u003c/ul\u003e\n  \u003c/p\u003e\n  \u003cp\u003eI am available to connect and discuss your technical projects and goals. I look forward to meeting the team and working with you.\u003c/p\u003e\n  \u003cp\u003e\n    Regards,\n    \u003cbr /\u003e\n    {{ full_name }}\n    \u003cbr /\u003e\n    Sr. Solutions Architect\n    \u003cbr /\u003e\n    Amazon Web Services\n  \u003c/p\u003e"
//   },
//   "PK": {
//       "Value": "mrkrchm"
//   },
//   "SK": {
//       "Value": "template#1666799387981"
//   },
//   "Title": {
//       "Value": "Pinkstaff"
//   },
//   "Type": {
//       "Value": "template"
//   }
// },

const columnDefinitions = [
  {
    id: "title",
    header: "Title",
    cell: (item) => item.Title,
    minWidth: 400,
  },
  {
    id: "SK",
    header: "Timestamp",
    sortingField: "Timestamp",
    cell: (item) => {
      const dt = parseInt(item.SK.split("#")[1]);
      return moment(dt).fromNow();
    },
    width: 200,
  },
  {
    id: "actions",
    cell: (item) => (
      <Button
        variant="primary"
        formAction="none"
        href={`/template/${item.SK.replace("template#", "")}`}
        iconAlign="undefined"
        iconName="undefined"
      >
        Edit
      </Button>
    ),
    header: "Actions",
    minWidth: 100,
  },
];

const tableEmpty = (
  <Box textAlign="center" color="inherit">
    <Box variant="p" color="inherit">
      No documents to display.
    </Box>
  </Box>
);

function ListTemplates() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    listTemplates()
      .then((data) => {
        console.log(data);
        setData(data);
        setTableLoading(false);
        postUserAction("listTemplates");
      })
      .catch(console.error);
  }, []);

  return (
    <Container header={<Header variant="h1">Templates</Header>}>
      <SpaceBetween direction="vertical" size="s">
        <Button
          iconName="refresh"
          onClick={() => {
            setTableLoading(true);
            listTemplates()
              .then((data) => {
                setData(data);
                setTableLoading(false);
              })
              .catch(console.error);
          }}
        >
          Refresh
        </Button>
        <Table
          loadingText="Loading templates..."
          columnDefinitions={columnDefinitions}
          items={data}
          empty={tableEmpty}
          loading={tableLoading}
          resizableColumns={true}
        />
      </SpaceBetween>
    </Container>
  );
}

export default ListTemplates;
